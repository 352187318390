export const environment = {
    production: true,
    profile: 'dev',
  
    // sandbox_logo: './assets/icons/logo_dark.svg',
    sandbox_icon: 'assets/icons/sandbox_icon.svg',
    sandbox_logo: 'assets/icons/logo_dark.svg',
    ic_sbox_brackets: 'assets/icons/ic_sbox_brackets.svg',
    mastercard_logo: 'assets/icons/mastercard.svg',
    visa_logo: 'assets/icons/visa.svg',
    rupay_logo: 'assets/icons/rupay.svg',
    myaccount_icon: 'assets/icons/my_account.svg',
    success_icon: 'assets/icons/success.svg',
    failure_icon: 'assets/icons/failure.svg',
    bhim_upi_icon: 'assets/icons/bhim_upi.svg',
    sandbox_grey_logo: 'assets/icons/sandbox_grey_logo.svg',
    bridge_icon: 'assets/icons/bridge.svg',
    codepen_icon: 'assets/icons/codepen.svg',
    apikey_icon: 'assets/icons/api_keys.svg',
    rupee_icon: 'assets/icons/rupee.svg',
    wallet_icon: 'assets/icons/wallet.svg',
    team_icon: 'assets/icons/team.svg',
    preference_icon: 'assets/icons/preference.svg',
    person_add_icon: '/assets/icons/person_add.svg',
    create_keys_icon: '/assets/icons/ic_create_keys.svg',
    open_in_new_tab_icon: '/assets/icons/open_in_new_tab.svg',
    sbox_brackets_footer_icon: '/assets/icons/ic_sbox_brackets.svg',
    plus_icon: "/assets/icons/plus.svg",
    black_plus_icon: "/assets/icons/black_plus.svg",
    upi_icon: "/assets/icons/upi_icon.svg",
    upi_logo: "/assets/icons/upi_logo.svg",
    ic_sandbox_logo_circle: '/assets/icons/ic_sandbox_logo_circle.svg',
    payment_methods_drawing: '/assets/images/payment-methods.svg',
    sandbox_account_logo: '/assets/icons/sandbox_account_logo.svg',
    account_circle_outline: '/assets/icons/account_circle_outline.svg',
  
    no_transactions_found_vector: '/assets/images/no_transactions_found.svg',
    configure_events_vector: '/assets/images/configure-events.svg',
    real_time_updates_vector: '/assets/images/real-time-updates.svg',
    event_based_workflow_vector: '/assets/images/event-based-workflow.svg',
    billing_machine_vector: '/assets/images/billing-machine.svg',
  
    authentication_host: 'http://dev1-accounts.sandbox.co.in',
  
    // Endpoints
    api_console_endpoint:
      'https://9j9sjg3cs4.execute-api.ap-south-1.amazonaws.com/dev/workspaces',
    org_quicko_cms_endpoint: 'https://d2l1ae64bvxp7e.cloudfront.net',
    com_quicko_cms_endpoint: 'https://d19cmtfrkn66yf.cloudfront.net',
    api_auth_endpoint:
      'https://keq7mx11sf.execute-api.ap-south-1.amazonaws.com/dev/auth',
    console_endpoint: "https://dev-console.sandbox.co.in",
  
  
    rzp_key: 'rzp_test_l1XkAgyZHMHH8i',
  };
  
import { NgModule } from "@angular/core";
import { DateFormatPipe } from "../pipes/DateFormatPipe";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        DateFormatPipe // Declare your pipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DateFormatPipe // Export your pipe
    ]
})
export class CommonComponentModule { }
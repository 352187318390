import { Expose } from 'class-transformer';
import 'reflect-metadata';
import { BaseObject } from './BaseObject';

@Reflect.metadata('@entity', 'in.co.sandbox.user')
export class User extends BaseObject {

    @Expose({ name: '@entity' })
    entity: string = 'in.co.sandbox.user';

    @Expose({ name: 'email' })
    email?: string;

    @Expose({ name: 'first_name' })
    firstName?: string;

    @Expose({ name: 'last_name' })
    lastName?: string;

    @Expose({ name: 'business' })
    business?: string;

    @Expose({ name: 'phone' })
    phone?: string;

    @Expose({ name: 'country_code' })
    countryCode?: string;

    @Expose({ name: 'password_updated_at' })
    passwordUpdatedAt?: number;

    @Expose({ name: 'is_password_set' })
    isPasswordSet?: boolean;
}

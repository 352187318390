import { ReducerTypes, createReducer, on } from "@ngrx/store";
import { UserActions } from "./user.actions";
import { User } from "../../beans/User";

const initialState: User | null = null;

const onGetUserSuccess = on(UserActions.GET_USER_SUCCESS, (userState: User | null, { user }) => {
    return user;
});


export const UserReducer = createReducer(
    initialState,
    onGetUserSuccess
);
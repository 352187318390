import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs";
import { ReferenceService } from "src/app/services/reference.service";
import { SnackbarService } from "../../services/snackbar.service";
import { ReferenceDataActions } from "./reference-data.actions";


@Injectable()
export class ReferenceDataEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private snackBarService: SnackbarService,
        private referenceDataService: ReferenceService
    ) { }

    getCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReferenceDataActions.GET_COUNTRIES),
            switchMap(() => this.referenceDataService.getCountries().pipe(
                map(response => {
                    return ReferenceDataActions.GET_COUNTRIES_SUCCESS({ countries: response.data })
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to get countries', undefined);
                    return ReferenceDataActions.GET_COUNTRIES_FAILED({ message: err })
                }
                ))
            ))
    );

}
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from '../../environments/environment';
import { ApiResponse } from "../beans/ApiResponse";

@Injectable({
    providedIn: 'root'
})
export class ReferenceService {
    private endpoint;
    private orgQuickoCmsEndpoint;
    private comQuickoEndpoint;
    referenceData: BehaviorSubject<any> | undefined;

    constructor(private httpClient: HttpClient) {
        this.endpoint = environment.api_console_endpoint;
        this.orgQuickoCmsEndpoint = environment.org_quicko_cms_endpoint;
        this.comQuickoEndpoint = environment.com_quicko_cms_endpoint;
    }

    getOnboardingReferenceData() {
        const url = this.endpoint + '/reference/in-co-sandbox-console-onboardings';
        return this.httpClient.get<ApiResponse<any>>(url);
    }


    getPostbackEventsReferenceData() {
        const url = this.endpoint + '/reference/in-co-sandbox-api-events';
        let params = new HttpParams().set('status', "active");
        return this.httpClient.get<ApiResponse<any>>(url, { params });
    }


    getApiCatalogueReferenceData() {
        const url = this.endpoint + '/reference/in-co-sandbox-console-api-catalogues';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

    getBankIcons() {
        const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-banks';
        let params = new HttpParams();
        params = params.set('populate', '*');
        return this.httpClient.get<ApiResponse<any>>(url, {
            params
        });
    }


    getPaymentIcons() {
        const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-payment-entities';
        let params = new HttpParams();
        params = params.set('populate', '*');
        return this.httpClient.get<ApiResponse<any>>(url, {
            params
        });
    }

    getStateCodeReferenceData() {
        const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-gst-state-codes';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

    getCountries() {
        const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-country-flags?populate=*';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

}
<header class="border-b-2 bg-white border-border-grey w-[100vw] min-w-[510px] z-10 fixed">
  <div class="flex flex-row justify-between items-center h-[64px] sticky top-0">

    <div class="flex flex-row items-center justify-center ml-8">
      <a [routerLink]="['./']">
        <img src="../../../../assets/icons/sandbox_account_logo.svg"  class="w-[200px] h-4 mr-12">
      </a>
    </div>


    <div class="flex flex-row items-center justify-center mr-[24px] gap-4">
      <div class="items-center text-body-1-semibold cursor-pointer text-navy-blue-800" (click)="navigateToConsole()">
      Go to Console
      </div>
      
      <button [matMenuTriggerFor]="accountMenu" class="w-6 h-6">
        <mat-icon class="w-6 h-6 text-2xl leading-6 material-symbols-rounded">
          account_circle</mat-icon>
      </button>
    </div>
    <mat-menu class="w-[337px] bg-white mt-3 shadow-md shadow-border-grey pb-0 overflow-x-hidden" #accountMenu="matMenu" xPosition="before">
      <ng-template matMenuContent >
        <div class="flex flex-row items-start">
          <div class="py-4 px-4">
            <ngx-avatars bgColor="#F6F6F6" fgColor="#A6A6A6" size="56"
              name="{{ (user$ | async)?.firstName + ' ' + (user$ | async)?.lastName }}">
            </ngx-avatars>
          </div>
          <div class="flex flex-col m-0 justify-between mt-5">
            <span class="text-body-1-medium text-dark">{{(user$ | async)?.firstName + " " + (user$ |
              async)?.lastName}}</span>
            <span class="text-caption-medium text-medium mt-2">{{(user$ | async)?.email}}</span>
          </div>
        </div>
        <mat-divider class="border-grey-bg-900"></mat-divider>
        <button (click)="logout()" mat-menu-item class="hover:bg-border-grey flex">
          <mat-icon class="material-symbols-rounded text-alert-error-500 mr-2 text-menu-item-icon" matPrefix>logout
          </mat-icon>
          <span class="text-caption-medium text-alert-error-500">Sign Out</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>

</header> 
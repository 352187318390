import { createAction, props } from '@ngrx/store';
import { User } from '../../beans/User';
import { v4 as uuidv4 } from 'uuid';

export const UserActions = {
    GET_USER: createAction('[USER] GET User ' + uuidv4()),
    GET_USER_SUCCESS: createAction('[USER] GET User Success ' + uuidv4(), props<{ user: User }>()),
    GET_USER_FAILED: createAction('[USER] GET User Failed ' + uuidv4(), props<{ message: string }>()),

    PATCH_USER: createAction('[USER] PATCH User ' + uuidv4(), props<{ user: User }>()),
    PATCH_USER_SUCCESS: createAction('[USER] PATCH User Success ' + uuidv4()),
    PATCH_USER_FAILED: createAction('[USER] PATCH User Failed ' + uuidv4(), props<{ message: string }>()),

    CHANGE_PASSWORD: createAction('[USER] CHANGE Password ' + uuidv4(), props<{ passwordBody: any }>()),
    CHANGE_PASSWORD_SUCCESS: createAction('[USER] CHANGE Password Success ' + uuidv4()),
    CHANGE_PASSWORD_FAILED: createAction('[USER] CHANGE Password Failed ' + uuidv4(), props<{ message: string }>()),

    SET_PASSWORD: createAction('[USER] SET Password ' + uuidv4(), props<{ passwordBody: any }>()),
    SET_PASSWORD_SUCCESS: createAction('[USER] SET Password Success ' + uuidv4()),
    SET_PASSWORD_FAILED: createAction('[USER] SET Password Failed ' + uuidv4(), props<{ message: string }>()),

    //The verify otp dialog will listen this action to resend otp rather than patch user action 
    //to avoid opening multiple dialog boxes. 
    RESEND_OTP: createAction('[USER] RESEND OTP ' + uuidv4(), props<{ user: User }>()),
    RESEND_OTP_SUCCESS: createAction('[USER] RESEND OTP Success' + uuidv4()),
    RESEND_OTP_FAILED: createAction('[USER] RESEND OTP Failed ' + uuidv4(), props<{ message: string }>()),
}
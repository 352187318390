import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { EntitlementsService } from './entitlements.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private cookieService: CookieService) {
    this.authenticated = new BehaviorSubject(this.isAuthenticated());
  }

  authenticated: BehaviorSubject<any>;
  jwtHelper: JwtHelperService = new JwtHelperService();

  getAuthenticated() {
    return this.authenticated.asObservable();
  }

  setAuthenticated(isAuthenticated: any) {
    this.authenticated.next(isAuthenticated);
  }

  public setToken(token: string): void {
    if (environment.production) {
      this.cookieService.delete('SESSIONID');
      this.cookieService.set('SESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
    }
    else {
      this.cookieService.delete('SESSIONID');
      this.cookieService.set('SESSIONID', token, undefined, undefined, undefined, false, 'Strict');
    }
  }

  public getToken(): string {
    return this.cookieService.get('SESSIONID');
  }

  public deleteToken(): void {
    if (environment.production) {
      this.cookieService.delete('SESSIONID', '/', '.sandbox.co.in', true, 'Strict');
    }
    else {
      this.cookieService.delete('SESSIONID', '/', 'localhost', true, 'Strict');
    }
  }

  public getUsername(): string | null {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['username'];
    }
    return null;
  }

  public getAudience(): string | null {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['aud'];
    }
    return null;
  }

  public isAuthenticated(): boolean {
    try {
      if (this.getToken() != null) {

        let token = this.getToken().split('.');

        if (token && _.size(token) == 3 && this.jwtHelper.decodeToken(this.getToken()) && !this.jwtHelper.isTokenExpired(this.getToken())) {
          return true;
        }
      }
    } catch (error) {
      return false;
    }
    return false;
  }

  getTokenExpiry(token: string): Date {
    return this.jwtHelper.getTokenExpirationDate(token)!;
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

}

@Injectable()
export class AuthErrorHandler implements ErrorHandler {

  authentication_host: string;
  constructor(private injector: Injector) {

    if (environment.production) {
      this.authentication_host = 'https://accounts.sandbox.co.in/';
    } else {
      this.authentication_host = 'http://localhost:4200/#/';
    }

  }

  handleError(error: any) {
    const router = this.injector.get(Router);
    if (error.rejection.status === 401 || error.rejection.status === 403) {
      window.location.href = this.authentication_host + 'login' + '?url=' + btoa(window.location.href);
    }
    throw error;
  }
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  authentication_host: string;
  constructor(public auth: AuthService) {


    if (environment.production) {
      if (environment.profile === 'prod') {
        this.authentication_host = 'https://accounts.sandbox.co.in/#/';
      } else {
        this.authentication_host = 'https://dev1-accounts.sandbox.co.in/';
      }
    } else {
      this.authentication_host = 'http://localhost:4200/#/';
    }

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (environment.production) {
      if (this.auth.isAuthenticated()) {
        request = request.clone({
          setHeaders:
          {
            'authorization': this.auth.getToken(),
          },
        });
      }
      else {
        window.location.href = this.authentication_host + 'login' + '?url=' + btoa(window.location.href);
      }
    }
    else {
      if (this.auth.isAuthenticated()) {
        let requestJSON: any = {
          setHeaders:
          {
            'authorization': this.auth.getToken(),
          }
        };
        if (request.url.includes('localhost')) {
          requestJSON["setParams"] =
          {
            'email': this.auth.getUsername(),
          }
        }

        request = request.clone(requestJSON);
      }
    }
    return next.handle(request);

  }
}

@Injectable({ providedIn: 'root' })
export class IsPasswordSet {

  redirectUrl: string | undefined;

  constructor(private router: Router, private entitlementsService: EntitlementsService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.entitlementsService.getUser().pipe(
      map(response => {
        console.log("hello there");
        if (response.code === 200 && response.data && !response.data.is_password_set) {
          console.log("true")
          return true; // Allow navigation to the route
        } else {
          console.log("no no");
          this.router.navigate(['./']); // Redirect to a different route (e.g., dashboard)
          return false; // Do not allow navigation to the route
        }
      })
    );
  }

}
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/common/header/header.component';
import { AuthService } from './services/auth.service';
import { ReferenceDataActions } from './store/reference-data/reference-data.actions';
import { UserActions } from './store/user/user.actions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StoreModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'my-account-app';
  constructor(private store: Store, private actions$: Actions, private authService: AuthService) { }
  ngOnInit(): void {
    if (!environment.production) {
      this.authService.setToken(
        'eyJraWQiOiJKblwvdmhvSGhTZ29McjhGY1I1RG4xTGdTb3AzS3NHeVhhRkFYR3NiYjlxVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwMjhmZmVkMy0xOGE5LTQ0NzYtOGIyNC02M2E4ZjkxMzYzMjIiLCJjb2duaXRvOmdyb3VwcyI6WyJhcC1zb3V0aC0xX2hxMjBsdEtFMl9Hb29nbGUiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoLTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGgtMV9ocTIwbHRLRTIiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIyMW03Ymw2bWRtMnVvbXYwNDFjNnBlbTVrNiIsIm9yaWdpbl9qdGkiOiIwZGU1Y2UwOS03Yzk1LTQwOGUtYjVhNy0xYzkyMjdkNTEzMjIiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNzI1MzU0ODc1LCJleHAiOjE3MjU0NDEyNzUsImlhdCI6MTcyNTM1NDg3NSwianRpIjoiODVkYTgwMzYtMmIyOC00NDI0LThhZTgtY2Q5ODBhZTYyMGNmIiwidXNlcm5hbWUiOiJnb29nbGVfMTA3MjU1NTc5NzY1MjY2NjUyMDU3In0.dT4zKoCTbC_wC4CnPZSfI4oLET5Ue1ecySGgeC9VHJZ_0iAivA_Kjql5QHvGLhQhBENdTSQWzHS5ab5Bwrd53Gp3tWOG9lW1aHwJ0xmnDEDiwq3OviKvzfXu6zfswEML63HXgUyhIUlMqpJwbYlfDgxflSygbynhyF7lrj4uUstEjM3rtdvUGOywqJyQSouogKXxKlMj2eBWNgOXvacNkpNPIs9sdmTnWS-riPgi7kRs2sIPUy9sRm9adXisvfRpvAhje_ZK4LyL3hlTqyOKsINLZzjmV9x_59IKXhv-MDGR0PAngpCGI6rY2E6t5mTj4fOUittRDolesBnjTIMo9w'
      )
    }
    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
    this.store.dispatch(UserActions.GET_USER())
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(epoch: number): string {
        const date = new Date(epoch);
        const day = date.getDate();
        const month = this.getMonthName(date.getMonth());
        const year = date.getFullYear();

        return `${this.getOrdinal(day)} ${month}, ${year}`;
    }

    private getOrdinal(n: number): string {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    }

    private getMonthName(month: number): string {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        return months[month];
    }
}
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SessionCookieService } from 'src/app/services/session-cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class LogoutComponent implements OnInit {

  constructor(private sessionCookieService: SessionCookieService) {
    if (typeof window !== "undefined") {
      this.sessionCookieService.deleteCookie('SESSIONID');
      window.location.href = environment.authentication_host + '/login' + '?url=' + btoa(window.location.href);
    }

  }

  ngOnInit() {
  }

}

<!-- TODO: Use ng-container and ng-template (done) -->
<ng-container *ngIf="loading; else content">
  <div class="flex flex-col mt-[32px] items-center mb-[134px]">
    <div class="flex flex-col w-[900px]">
      <div class="flex flex-col items-center mb-[40px]">
        <ngx-skeleton-loader [theme]="{ height: '70px', width: '70px', margin: '0px' }"
          class="mb-[16px]"></ngx-skeleton-loader>

        <div class="flex flex-col w-[223px] h-[52px] bg-grey-bg-200 items-center">
          <ngx-skeleton-loader [theme]="{ height: '30px', width: '223px', margin: '0px' }"
            class="mb-[4px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '21px', width: '201px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="flex flex-row">
        <ngx-skeleton-loader [theme]="{ height: '27px', width: '138px', margin: '0px' }"
          class="mb-[8px]"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader [theme]="{ height: '21px', width: '500px', margin: '0px' }"
        class="mb-[16px]"></ngx-skeleton-loader>
      <div class="flex flex-col w-[900px] h-[240px] bg-grey-bg-200">
        <div class="flex flex-row mt-[16px] ml-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '73px', margin: '0px' }"
            class="mr-[195px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
        <div class="flex flex-row mt-[16px] ml-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '112px', margin: '0px' }"
            class="mr-[156px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
        <div class="flex flex-row mt-[16px] ml-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '116px', margin: '0px' }"
            class="mr-[152px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
        <div class="flex flex-row mt-[40px] ml-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '117px', margin: '0px' }"
            class="mr-[195px]"></ngx-skeleton-loader>
        </div>
      </div>
      <ngx-skeleton-loader [theme]="{ height: '27px', width: '159px', margin: '0px' }"
        class="mt-[40px] mb-[8px]"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ height: '21px', width: '500px', margin: '0px' }"
        class="mb-[16px]"></ngx-skeleton-loader>
      <div class="flex flex-row">
        <div class="flex flex-col w-[442px] h-[208px] bg-grey-bg-200 pl-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '32px', width: '32px', margin: '0px' }"
            class="mt-[16px] mb-[16px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '109px', margin: '0px' }"
            class="mb-[8px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '219px', margin: '0px' }"
            class="mb-[24px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '105px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
        <div class="flex flex-col w-[442px] h-[208px] ml-[16px] bg-grey-bg-200 pl-[24px]">
          <ngx-skeleton-loader [theme]="{ height: '32px', width: '32px', margin: '0px' }"
            class="mt-[16px] mb-[16px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '75px', margin: '0px' }"
            class="mb-[8px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '194px', margin: '0px' }"
            class="mb-[24px]"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '139px', margin: '0px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <div class="flex flex-col mt-[32px] items-center">
    <div class="flex flex-col w-[900px] items-center">
      <div class="flex flex-col items-center">
        <!-- TODO: Do not make circle using CSS. Use ngx-avatar library (done)-->
        <ngx-avatars [round]="true" bgColor="#D3D3EE" fgColor="#393993" size="70" [textSizeRatio]="2"
          [name]='this.user?.firstName + " " + user?.lastName'>
        </ngx-avatars>
        <div class="flex flex-col mt-[16px] items-center">
          <span class="text-subheading-1-medium text-dark">Welcome, {{ this.user?.firstName }} {{ this.user?.lastName
            }}</span>
          <span class="mt-[4px] text-caption-medium text-medium">Joined on {{ this.user?.createdAt | dateFormat
            }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col mt-[40px]">
        <!-- TODO: Text-medium in subheadings (done)-->
        <span class="text-body-2-medium text-dark">Personal details</span>
        <span class="mt-[8px] text-caption-medium text-medium">Manage your personal identity across sandbox</span>
      </div>
      <mat-card appearance="outlined"
        class="flex flex-col border rounded-lg border-solid border-grey-bg-900 w-[900px] mt-[16px] mat-elevation-z0">
        <div class="flex flex-col w-[900px] py-[8px]">
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Full name</span>
            <span class="text-body-1-medium text-navy-blue-800 ml-[195px]">{{ this.user?.firstName }} {{
              this.user?.lastName }}</span>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Phone number</span>
            <span *ngIf="this.user?.phone" class="text-body-1-medium text-navy-blue-800 ml-[156px]">
              {{this.user?.phone.slice(0,3)}} {{formatPhoneNumber(this.user?.phone)}}</span>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Business name</span>
            <span *ngIf="this.user?.business" class="text-body-1-medium text-navy-blue-800 ml-[152px]">{{
              this.user?.business
              }}</span>
          </mat-card-content>
        </div>
        <mat-divider class="border-grey-bg-900"></mat-divider>
        <mat-card-actions class="flex flex-row w-[900px]" matRipple matRippleColor="#F0F0F9">
          <button routerLink="./details">
            <span class="text-navy-blue-600 text-body-1-medium pr-[745px] px-[16px] py-[16px]">Change Details</span>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col mt-[40px]">
        <span class="text-body-2-medium text-dark">Login and security</span>
        <span class="mt-[8px] text-caption-medium text-medium">Manage your login credentials used to access
          Sandbox</span>
      </div>
      <div class="flex flex-row mt-[16px]">
        <mat-card appearance="outlined"
          class="flex flex-col border rounded-lg border-solid border-grey-bg-900 w-[442px]">
          <mat-card-content class="flex flex-col py-[16px] px-[24px]">
            <mat-icon class="material-symbols-rounded text-navy-blue-600 h-[32px] w-[32px] my-icon">email</mat-icon>
            <span class="flex flex-row text-body-1-medium text-medium mt-[16px]">Email Address</span>
            <span class="flex flex-row text-body-1-medium mt-[8px] text-dark">{{
              this.user?.email
              }}</span>
          </mat-card-content>

          <mat-divider class="bg-grey"></mat-divider>

          <mat-card-actions class="flex flex-row" matRipple matRippleColor="#F0F0F9">
            <button routerLink="./email">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px] pr-[300px]">Change Email</span>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card appearance="outlined"
          class="flex flex-col ml-[16px] border rounded-lg border-solid border-grey-bg-900 w-[442px] mat-elevation-z0">
          <mat-card-content class="flex flex-col py-[16px] px-[24px]">
            <mat-icon class="material-symbols-rounded text-navy-blue-600 h-[32px] w-[32px] my-icon">password</mat-icon>
            <span class="flex flex-row text-body-1-medium text-medium mt-[16px]">Password</span>
            <span class="flex flex-row text-body-1-medium mt-[8px] text-dark">{{ passwordMessage }}</span>
          </mat-card-content>

          <mat-divider class="border-grey-bg-900"></mat-divider>

          <mat-card-actions class="flex flex-row" matRipple matRippleColor="#F0F0F9">
            <button *ngIf="this.user?.isPasswordSet" routerLink="./password">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px]">Change Password</span>
            </button>
            <button *ngIf="!this.user?.isPasswordSet" routerLink="./set-password">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px]">Set Password</span>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</ng-template>
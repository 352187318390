import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs";
import { EntitlementsService } from "../../services/entitlements.service";
import { UserActions } from "./user.actions";
import { SnackbarService } from "../../services/snackbar.service";
import { classToPlain, instanceToPlain, plainToClass } from "class-transformer";
import { User } from "../../beans/User";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private entitlementService: EntitlementsService,
        private store: Store,
        private snackBarService: SnackbarService
    ) { }

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.GET_USER),
            switchMap(() => this.entitlementService.getUser().pipe(
                map(response => {
                    const user = plainToClass(User, response.data);
                    user.setId(response.data.user_id);
                    return UserActions.GET_USER_SUCCESS({ user: user })
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to get user', undefined);
                    return UserActions.GET_USER_FAILED({ message: err })
                }
                ))
            ))
    );

    patchUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.PATCH_USER),
            switchMap((action) => this.entitlementService.updateUser(instanceToPlain(action.user)).pipe(
                map(() => {
                    this.store.dispatch(UserActions.GET_USER());
                    return UserActions.PATCH_USER_SUCCESS();
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to update user', undefined);
                    return UserActions.PATCH_USER_FAILED({ message: err })
                }
                )
            ))
        )
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.CHANGE_PASSWORD),
            switchMap((action) => this.entitlementService.changePassword(action.passwordBody).pipe(
                map(response => {
                    return UserActions.CHANGE_PASSWORD_SUCCESS();
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to change password', undefined);
                    return UserActions.CHANGE_PASSWORD_FAILED({ message: err })
                }
                ))
            ))
    );

    setPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.SET_PASSWORD),
            switchMap((action) => this.entitlementService.setPassword(action.passwordBody).pipe(
                map(response => {
                    return UserActions.SET_PASSWORD_SUCCESS();
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to set password', undefined);
                    return UserActions.SET_PASSWORD_FAILED({ message: err })
                }
                ))
            ))
    );

    resendOtp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.RESEND_OTP),
            switchMap((action) => this.entitlementService.updateUser(instanceToPlain(action.user)).pipe(
                map(() => {
                    return UserActions.RESEND_OTP_SUCCESS();
                }),
                catchError(async err => {
                    this.snackBarService.openSnackBar('Failed to send otp', undefined);
                    return UserActions.RESEND_OTP_FAILED({ message: err })
                }
                )
            ))
        )
    );

}
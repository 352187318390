import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'ngx-avatars';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonComponentModule } from 'src/app/modules/common-component.module';
import { DateFormatPipe } from 'src/app/pipes/DateFormatPipe';
import { User } from '../../beans/User';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { UserActions } from '../../store/user/user.actions';
import { UserSelectors } from '../../store/user/user.selectors';




@Component({
  selector: 'app-editable-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, AvatarModule, NgxSkeletonLoaderModule, RouterModule, CommonComponentModule]
})
export class MyAccountComponent implements OnInit {
  destroy$: Subject<void> = new Subject<void>();
  user$: Observable<User> = this.store.select(UserSelectors.USER);
  user: User;
  passwordMessage: string | undefined;
  loading: boolean = true;
  datePipe: DateFormatPipe;
  //TODO: Date should be displayed according to the design.
  constructor(private store: Store, public dialog: MatDialog, private actions$: Actions, private router: Router) {

    // this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd),
    //   takeUntil(this.destroy$)
    // ).subscribe(() => {
    //   // Dispatch GET_USER action on every navigation end
    //   this.store.dispatch(UserActions.GET_USER());
    // });



  }

  ngOnInit() {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      if (user) {
        this.user = user;
        this.loading = false;
        this.passwordMessage = this.getPasswordUpdateMessage();
      }
    });



    this.actions$.pipe(ofType(UserActions.CHANGE_PASSWORD_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
      this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
        this.user = user;
        this.passwordMessage = this.getPasswordUpdateMessage();
      });
      this.loading = false;
    })
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPasswordUpdateMessage(): string {
    if (!this.user.isPasswordSet) {
      return 'Password not set';
    }
    if (!this.user?.passwordUpdatedAt) {
      return 'Password never changed';
    }
    let days = Math.floor((Date.now() - this.user.passwordUpdatedAt!) / (1000 * 3600 * 24))

    if (days <= 0) {
      return 'Last changed today';
    } else if (days === 1) {
      return 'Last changed 1 day ago';
    } else {
      return `Last changed ${days} days ago`;
    }
  }

  formatPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber.slice(3);
    // Remove all non-numeric characters
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Add spacing every five digits
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{5})/g, '$1 ').trim();

    return formattedPhoneNumber;
  }

}

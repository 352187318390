import { prop } from "@rxweb/reactive-form-validators";
import { Expose } from "class-transformer";

export class BaseObject {

    @Expose({ name: 'id' })
    @prop()
    id?: string;

    @Expose({ name: 'description' })
    @prop()
    description?: string;

    @Expose({ name: 'created_at' })
    @prop()
    createdAt?: number;

    @Expose({ name: 'updated_at' })
    @prop()
    updatedAt?: number;

    constructor() { }
    getId() {
        return this.id;
    }

    setId(value: string | undefined) {
        this.id = value;
    }

}
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/common/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) { }

    openSnackBar(message: string, action: string | undefined) {

        this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message },
            duration: 3000,
            panelClass: ['blue-snackbar']
        })
    }
}

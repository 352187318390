import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { AngularMaterialModule } from '../../../modules/angular-material/angular-material.module';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css'],
  imports:[AngularMaterialModule, RouterModule],
  standalone:true
})
export class SnackbarComponent {

  constructor(public snackBarRef: MatSnackBarRef<SnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {

  }
  close() {
    this.snackBarRef.dismiss();
  }

}

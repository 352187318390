import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { User } from '../../../beans/User';
import { UserSelectors } from '../../../store/user/user.selectors';
import { SessionCookieService } from '../../../services/session-cookie.service';
import { environment } from '../../../../environments/environment';
import { Actions } from '@ngrx/effects';
import { UserActions } from '../../../store/user/user.actions';
import { AngularMaterialModule } from '../../../modules/angular-material/angular-material.module';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'ngx-avatars';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sandbox-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, CommonModule, AvatarModule, RouterModule],
})

export class HeaderComponent implements OnInit {
  user$: Observable<User> = this.store.select(UserSelectors.USER);
  destroy$: Subject<boolean> = new Subject<boolean>();
  isFreeTrial: boolean = false;

  constructor(private router: Router,
    private sessionCookieService: SessionCookieService,
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer) {

    iconRegistry.addSvgIcon(
      'sandbox_account_logo',
      sanitizer.bypassSecurityTrustResourceUrl(environment.sandbox_account_logo)
    );

  }

  ngOnInit() {
    this.store.dispatch(UserActions.GET_USER());
  }

  logout() {
    this.sessionCookieService.deleteCookie('SESSIONID');
    window.location.href = environment.authentication_host + '/login' + '?url=' + btoa(window.location.href);
  }

  navigateToConsole() {
    window.location.href = environment.console_endpoint;
  }

}

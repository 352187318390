<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">
    <div class="flex flex-row">
      <button routerLink="../" class="flex flex-row content-center">
        <mat-icon class="material-symbols-rounded text-navy-blue-600 my-icon">arrow_back</mat-icon>
        <span class="text-navy-blue-600 text-body-1-medium ml-[4px]">Back</span>
      </button>
    </div>
    <div class="flex flex-col mt-[8px] mb-[40px]">
      <span class="text-body-2-medium">Change Password</span>
      <span class="mt-[4px] text-body-1-regular text-medium">Set a strong password to secure your account</span>
    </div>
    <form [formGroup]="passwordForm">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>Current Password</mat-label>

            <input matInput formControlName="old_password" type="password" [type]="showPassword ? 'text' : 'password'"
              onpaste="return false" ondrop="return false" autocomplete="off" placeholder="Current Password"
              class="text-caption-medium text-dark">
            <mat-error [ngClass]="'custom-mat-error'" *ngIf="passwordForm.controls['old_password'].errors"
              class="w-[415px] pl-0">
              <mat-hint *ngIf="passwordForm.controls['old_password'].errors['required']"
                class="text-red-500 text-xs pl-0">
                Required
              </mat-hint>

              <mat-hint *ngIf="passwordForm.controls['old_password'].errors['invalidPassword']"
                class="text-red-500 text-xs pl-0">
                Must be at least 8 characters long, have at least one lowercase
                character, one uppercase character, one number, and one special character
              </mat-hint>
            </mat-error>

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=false"
              *ngIf="showPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=true"
              *ngIf="!showPassword">
              visibility_off</mat-icon>

          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="check_password" type="password"
              [type]="showNewPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
              autocomplete="off" placeholder="New Password" class="text-caption-medium text-dark">
            <!-- 
            <mat-hint *ngIf="!passwordForm.controls['check_password'].errors" class="text-medium text-xs -ml-4">Must be at least 8 characters long, have at least one lowercase character, one uppercase character, one number, and one special character
            </mat-hint>
            <mat-error [ngClass]="'custom-mat-error'" *ngIf="passwordForm.controls['check_password'].errors" class="w-[415px]">
              <mat-hint *ngIf="passwordForm.controls['check_password'].errors['required']" class="text-red-500 text-xs -ml-4">
                Required
              </mat-hint>

              <mat-hint *ngIf="passwordForm.controls['check_password'].errors['invalidPassword']" class="text-red-500 text-xs -ml-4">Must be at least 8 characters long, have at least one lowercase character, one uppercase character, one number, and one special character
              </mat-hint>
            </mat-error> -->

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showNewPassword=false"
              *ngIf="showNewPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showNewPassword=true"
              *ngIf="!showNewPassword">
              visibility_off</mat-icon>
          </mat-form-field>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-row gap-2"><img *ngIf="!isLengthValid" class="w-5 h-5"
              src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isLengthValid" class="w-5 h-5"
              src="assets/icons/check_circle_enabled.svg" alt="">
            <p class="text-caption-medium">Must be at least 8 characters long</p>
          </div>
          <div class="flex flex-row gap-2"><img *ngIf="!isLowercaseValid" class="w-5 h-5"
              src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isLowercaseValid" class="w-5 h-5"
              src="assets/icons/check_circle_enabled.svg" alt="">
            <p class="text-caption-medium">Must contain at least one lowercase character</p>
          </div>
          <div class="flex flex-row gap-2"><img *ngIf="!isUppercaseValid" class="w-5 h-5"
              src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isUppercaseValid" class="w-5 h-5"
              src="assets/icons/check_circle_enabled.svg" alt="">
            <p class="text-caption-medium">Must contain at least one uppercase character</p>
          </div>
          <div class="flex flex-row gap-2"><img *ngIf="!isNumberValid" class="w-5 h-5"
              src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isNumberValid" class="w-5 h-5"
              src="assets/icons/check_circle_enabled.svg" alt="">
            <p class="text-caption-medium">Must contain at least one number</p>
          </div>
          <div class="flex flex-row gap-2"><img *ngIf="!isSpecialCharacterValid" class="w-5 h-5"
              src="assets/icons/check_circle_disabled.svg" alt=""><img *ngIf="isSpecialCharacterValid" class="w-5 h-5"
              src="assets/icons/check_circle_enabled.svg" alt="">
            <p class="text-caption-medium">Must include at least one special character</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-[20px]">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="w-[600px] shadow-navy-blue-600 text-border-grey rounded-md">
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="new_password" type="password"
              [type]="showConfirmPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
              autocomplete="off" placeholder="Confirm Password" class="text-caption-medium text-dark ">

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=false"
              *ngIf="showConfirmPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=true"
              *ngIf="!showConfirmPassword">
              visibility_off</mat-icon>

            <mat-error *ngIf="passwordForm.controls['new_password'].errors" class="text-alert-error-900">
              <mat-hint *ngIf="passwordForm.controls['new_password']!.errors!['required']"
                class="text-red-500 text-xs -ml-4">
                Required
              </mat-hint>
              <mat-hint *ngIf="passwordForm.controls['new_password']!.errors['match_error']"
                class="text-red-500 text-xs -ml-4">
                Password does not match
              </mat-hint>
            </mat-error>

          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row mt-[16px]">
        <ng-container *ngIf="!showRive; else proceed">
          <button (click)="resetPassword()" type="submit" mat-flat-button [disabled]="!this.passwordForm.dirty"
            class="w-[104px] disabled:bg-slate-400 rounded-md bg-navy-blue-800 px-[16px] py-[10px] h-[44px]">
            <span class="text-white text-caption-medium">Save</span>
          </button>
        </ng-container>
        <ng-template #proceed>
          <button mat-flat-button class="w-[120px] rounded-md bg-navy-blue-800 px-[16px] py-[10px] h-[44px]">
            <canvas riv="console-cta-button-loading-animation" width="48" height="48">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>
        <button type="submit" mat-flat-button routerLink="../"
          class="ml-4 w-[104px] bg-transparent border-solid border-[1px] px-[16px] py-[10px] h-[44px] border-navy-blue-800 rounded-md">
          <span class="text-navy-blue-800 text-caption-medium">Cancel</span>
        </button>
      </div>
    </form>
  </div>
</div>
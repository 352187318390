import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RiveLinearAnimation, RiveModule } from 'ng-rive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CountrySelectors } from 'src/app/store/reference-data/reference-data.selectors';
import { User } from '../../beans/User';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { ReferenceService } from '../../services/reference.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';
import { UserSelectors } from '../../store/user/user.selectors';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, CommonModule, RiveModule, ReactiveFormsModule, RouterModule, RiveLinearAnimation]
})
export class EditDetailsComponent implements OnInit {
  destroy$: Subject<void> = new Subject<void>();
  user$: Observable<User> = this.store.select(UserSelectors.USER);
  user: User | undefined;
  userCountryCode = 91;
  loading = true;
  detailsForm: FormGroup;
  selectedCountry: any;
  countryList: any;
  countryList$: Observable<Array<any>> = this.store.select(CountrySelectors.COUNTRIES)
  //TODO: not required (done)
  //TODO: showRive = false (done)

  showRive: boolean = false;


  constructor(private referenceService: ReferenceService, private store: Store, private actions$: Actions, private snackbarService: SnackbarService, private router: Router, private route: ActivatedRoute) {
    this.detailsForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.pattern(/^\d{5} \d{5}$/), Validators.maxLength(15), Validators.minLength(10)]),
      business: new FormControl('', [Validators.required]),
    });

  }


  ngOnInit() {


    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      if (user) {
        this.user = user;
        this.detailsForm.patchValue(this.user)
        if (this.user.phone != null) {
          //TODO:: Use negative indexing.(done)
          this.detailsForm.patchValue({ phone: this.formatPhoneNumber(this.user.phone.slice(3)) })
        }

        this.countryList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
          this.countryList = data
          if (this.user?.phone == null && this.user?.countryCode == null) { this.selectedCountry = this.countryList.find((c: any) => c.numeric_code == 91) }
          //TODO: Remove this code.(done)
          if (!this.selectedCountry) {
            this.selectedCountry = this.countryList.find((c: any) => c.numeric_code == (this.user?.countryCode))
          }
          this.loading = false;
        })
      }
    });

    this.detailsForm.controls['phone'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value && value.length <= 15 && value.length > 0) {
          // Format phone number with space
          this.detailsForm.controls['phone'].setValue(this.formatPhoneNumber(value), { emitEvent: false });
        }
      });
  }

  saveDetails() {
    const phoneNumberWithoutSpaces = this.detailsForm.controls['phone'].value.replace(/\s/g, '');
    const phoneNumber: string = '+' + this.selectedCountry.numeric_code + phoneNumberWithoutSpaces;
    this.user = new User();
    this.user.firstName = (this.detailsForm.controls['firstName'].value);
    this.user.lastName = (this.detailsForm.controls['lastName'].value);
    this.user.business = (this.detailsForm.controls['business'].value);
    this.user.phone = (phoneNumber);
    this.user.countryCode = (this.selectedCountry.numeric_code.toString());
    if (this.detailsForm.valid && (this.detailsForm.touched || this.detailsForm.dirty)) {
      this.showRive = true;
      this.store.dispatch(UserActions.PATCH_USER({ user: this.user }))

      this.actions$.pipe(ofType(UserActions.PATCH_USER_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Personal details updated successfully', undefined)
        this.showRive = false;
        this.router.navigate(['../'], {
          relativeTo: this.route,
        })
      })

      this.actions$.pipe(ofType(UserActions.PATCH_USER_FAILED), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Failed to update Details', undefined);
        this.showRive = false;
      })
    }
  }

  changePhoneNumber() {
    this.detailsForm.controls['phone'].markAsDirty();
  }

  formatPhoneNumber(phoneNumber: string) {
    // Remove all non-numeric characters
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    // Add spacing every five digits
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{5})/g, '$1 ').trim();

    return formattedPhoneNumber;
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }

}
